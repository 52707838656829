<template>
  <a-modal
    title="处理任务"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="renewProgressApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="任务名称"
      >
        {{ missionInfo.title }}
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="任务进度"
      >
        <a-progress
          title="任务进度"
          :strokeColor="{'0%': '#108ee9','100%': '#87d068' }"
          :percent="progress"
        />
        <a-button-group>
          <a-button @click="decline" :style="{ padding: '0 10px 0 10px' }">
            <a-icon type="minus" />
          </a-button>
          <a-button @click="increase" :style="{ padding: '0 10px 0 10px' }">
            <a-icon type="plus" />
          </a-button>
        </a-button-group>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="任务状态"
        :validateStatus="checkParams.missionStatusStatus"
        :help="checkParams.missionStatusStatusMsg"
      >
        <a-select
          style="width:170px"
          v-model="missionStatus"
          placeholder="请选择状态"
          @change="changeMissionStatus"
        >
          <a-select-option :key="-1" :value="-1">请选择状态</a-select-option>
          <a-select-option v-for="item in statusList" :key="item.id" :value="item.id" v-if="item.id !== 0 && item.id !== 10 && item.id !== 40">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import { renewProgress } from '@/api/mission'

export default {
  name: 'DealMission',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      missionInfo: {},
      progress: 0,
      confirmLoading: false,
      tabindex: 1,
      missionStatus: 0,
      checkParams: {
        missionStatusStatus: 'success',
        missionStatusStatusMsg: null
      }
    }
  },
  created () {
  },
  methods: {
    ininData (data, tabindex = 1) {
      this.checkParams.missionStatusStatus = 'success'
      this.checkParams.missionStatusStatusMsg = null
      this.missionInfo = data
      this.progress = this.missionInfo.progress
      if (this.missionInfo.status < 20) {
        this.missionStatus = -1
      } else {
        this.missionStatus = this.missionInfo.status
      }
      this.tabindex = tabindex
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    decline () {
      var gress = this.progress - 5
      this.progress = gress < 0 ? 0 : gress
      if (this.progress > 0 && this.progress < 100) {
        this.missionStatus = 20
      } else if (this.progress === 0) {
        this.missionStatus = this.missionInfo.status
      } else {
        this.missionStatus = this.missionInfo.status
      }

      if (this.missionStatus < 20) {
        this.missionStatus = -1
      }
    },
    increase () {
      var gress = this.progress + 5
      this.progress = gress > 100 ? 100 : gress
      if (this.progress > 0 && this.progress < 100) {
        this.missionStatus = 20
      } else if (this.progress === 100) {
        this.missionStatus = 30
      } else {
        this.missionStatus = this.missionInfo.status
      }
      if (this.missionStatus < 20) {
        this.missionStatus = -1
      }
    },
    changeMissionStatus (e) {
      var status = parseInt(e)
      if (status === 30) {
        this.progress = 100
      } else if (status === 0) {
        this.progress = 0
      } else {
        if (this.progress === 100) {
          this.progress = this.missionInfo.progress
        }
      }
      if (status !== -1) {
        this.checkParams.missionStatusStatus = 'success'
        this.checkParams.missionStatusStatusMsg = null
      } else {
        this.checkParams.missionStatusStatus = 'error'
        this.checkParams.missionStatusStatusMsg = '请选择状态'
      }
    },
    renewProgressApi () {
      const _this = this
      if (_this.missionStatus !== -1) {
        _this.checkParams.missionStatusStatus = 'success'
        _this.checkParams.missionStatusStatusMsg = null
      } else {
        _this.checkParams.missionStatusStatus = 'error'
        _this.checkParams.missionStatusStatusMsg = '请选择状态'
        return false
      }
      const params = {
        missionKeyId: this.missionInfo.keyId,
        progress: _this.progress,
        status: _this.missionStatus
      }
      _this.confirmLoading = true
      renewProgress(params).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.visible = false
          this.$emit('ok', this.tabindex)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.confirmLoading = false
      }).catch((err) => {
        console.log(err)
        _this.confirmLoading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
